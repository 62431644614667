@use "sass:math";

.hero-widget {
  margin-bottom: 10px;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);

  &:last-child {
    margin-bottom: 0;
  }

  &__img {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    background: $base-shadow-color;

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 4px 4px 0 0;
    }
  }

  &__text {
    background: $ui-base-color;
    padding: 20px;
    border-radius: 0 0 4px 4px;
    font-size: 15px;
    color: $darker-text-color;
    line-height: 20px;
    word-wrap: break-word;
    font-weight: 400;

    .emojione {
      width: 20px;
      height: 20px;
      margin: -3px 0 0;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    em {
      display: inline;
      margin: 0;
      padding: 0;
      font-weight: 700;
      background: transparent;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      color: lighten($darker-text-color, 10%);
    }

    a {
      color: $secondary-text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    display: none;
  }
}

.endorsements-widget {
  margin-bottom: 10px;
  padding-bottom: 10px;

  h4 {
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: $darker-text-color;
  }

  .account {
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }

    .account__display-name {
      display: flex;
      align-items: center;
    }
  }

  .trends__item {
    padding: 10px;
  }
}

.trends-widget {
  h4 {
    color: $darker-text-color;
  }
}

.box-widget {
  padding: 20px;
  border-radius: 4px;
  background: $ui-base-color;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
}

.placeholder-widget {
  padding: 16px;
  border-radius: 4px;
  border: 2px dashed $dark-text-color;
  text-align: center;
  color: $darker-text-color;
  margin-bottom: 10px;
}

.contact-widget {
  min-height: 100%;
  font-size: 15px;
  color: $darker-text-color;
  line-height: 20px;
  word-wrap: break-word;
  font-weight: 400;
  padding: 0;

  h4 {
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: $darker-text-color;
  }

  .account {
    border-bottom: 0;
    padding: 10px 0;
    padding-top: 5px;
  }

  & > a {
    display: inline-block;
    padding: 10px;
    padding-top: 0;
    color: $darker-text-color;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.moved-account-widget {
  padding: 15px;
  padding-bottom: 20px;
  border-radius: 4px;
  background: $ui-base-color;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  color: $secondary-text-color;
  font-weight: 400;
  margin-bottom: 10px;

  strong,
  a {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &.mention {
      text-decoration: none;

      span {
        text-decoration: none;
      }

      &:focus,
      &:hover,
      &:active {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  &__message {
    margin-bottom: 15px;

    .fa {
      margin-right: 5px;
      color: $darker-text-color;
    }
  }

  &__card {
    .detailed-status__display-avatar {
      position: relative;
      cursor: pointer;
    }

    .detailed-status__display-name {
      margin-bottom: 0;
      text-decoration: none;

      span {
        font-weight: 400;
      }
    }
  }
}

.memoriam-widget {
  padding: 20px;
  border-radius: 4px;
  background: $base-shadow-color;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  font-size: 14px;
  color: $darker-text-color;
  margin-bottom: 10px;
}

.page-header {
  background: lighten($ui-base-color, 8%);
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  border-radius: 4px;
  padding: 60px 15px;
  text-align: center;
  margin: 10px 0;

  h1 {
    color: $primary-text-color;
    font-size: 36px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    color: $darker-text-color;
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    margin-top: 0;
    background: lighten($ui-base-color, 4%);

    h1 {
      font-size: 24px;
    }
  }
}

.directory {
  background: $ui-base-color;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);

  &__tag {
    box-sizing: border-box;
    margin-bottom: 10px;

    & > a,
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $ui-base-color;
      border-radius: 4px;
      padding: 15px;
      text-decoration: none;
      color: inherit;
      box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
    }

    & > a {
      &:hover,
      &:active,
      &:focus {
        background: lighten($ui-base-color, 8%);
      }
    }

    &.active > a {
      background: var(--ui-highlight-color);
      cursor: default;
    }

    &.disabled > div {
      opacity: 0.5;
      cursor: default;
    }

    h4 {
      flex: 1 1 auto;
      font-size: 18px;
      font-weight: 700;
      color: $primary-text-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .fa {
        color: $darker-text-color;
      }

      small {
        display: block;
        font-weight: 400;
        font-size: 15px;
        margin-top: 8px;
        color: $darker-text-color;
      }
    }

    &.active h4 {
      &,
      .fa,
      small,
      .trends__item__current {
        color: $primary-text-color;
      }
    }

    .avatar-stack {
      flex: 0 0 auto;
      width: (36px + 4px) * 3;
    }

    &.active .avatar-stack .account__avatar {
      border-color: var(--ui-highlight-color);
    }

    .trends__item__current {
      padding-right: 0;
    }
  }
}

.avatar-stack {
  display: flex;
  justify-content: flex-end;

  .account__avatar {
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    margin-left: -10px;
    background: darken($ui-base-color, 8%);
    border: 2px solid $ui-base-color;

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 3;
    }
  }
}

.accounts-table {
  width: 100%;

  .account {
    padding: 0;
    border: 0;
  }

  strong {
    font-weight: 700;
  }

  thead th {
    text-align: center;
    text-transform: uppercase;
    color: $darker-text-color;
    font-weight: 700;
    padding: 10px;

    &:first-child {
      text-align: left;
    }
  }

  tbody td {
    padding: 15px 0;
    vertical-align: middle;
    border-bottom: 1px solid lighten($ui-base-color, 8%);
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }

  &__count {
    width: 120px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: $primary-text-color;

    small {
      display: block;
      color: $darker-text-color;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__comment {
    width: 50%;
    vertical-align: initial !important;
  }

  &__interrelationships {
    width: 21px;
  }

  .fa {
    font-size: 16px;

    &.active {
      color: $highlight-text-color;
    }

    &.passive {
      color: $passive-text-color;
    }

    &.active.passive {
      color: $active-passive-text-color;
    }
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    tbody td.optional {
      display: none;
    }
  }
}

.moved-account-widget,
.memoriam-widget,
.box-widget,
.contact-widget,
.landing-page__information.contact-widget,
.directory,
.page-header {
  @media screen and (max-width: $no-gap-breakpoint) {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
  }
}

$maximum-width: 1235px;
$fluid-breakpoint: $maximum-width + 20px;

.statuses-grid {
  min-height: 600px;

  @media screen and (max-width: 640px) {
    width: 100% !important; // Masonry layout is unnecessary at this width
  }

  &__item {
    width: math.div(960px - 20px, 3);

    @media screen and (max-width: $fluid-breakpoint) {
      width: math.div(940px - 20px, 3);
    }

    @media screen and (max-width: 640px) {
      width: 100%;
    }

    @media screen and (max-width: $no-gap-breakpoint) {
      width: 100vw;
    }
  }

  .detailed-status {
    border-radius: 4px;

    @media screen and (max-width: $no-gap-breakpoint) {
      border-top: 1px solid lighten($ui-base-color, 16%);
    }

    &.compact {
      .detailed-status__meta {
        margin-top: 15px;
      }

      .status__content {
        font-size: 15px;
        line-height: 20px;

        .emojione {
          width: 20px;
          height: 20px;
          margin: -3px 0 0;
        }

        .status__content__spoiler-link {
          line-height: 20px;
          margin: 0;
        }
      }

      .media-gallery,
      .status-card,
      .video-player {
        margin-top: 15px;
      }
    }
  }
}

.notice-widget {
  margin-bottom: 10px;
  color: $darker-text-color;

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: 14px;
    line-height: 20px;
  }
}

.notice-widget,
.placeholder-widget {
  a {
    text-decoration: none;
    font-weight: 500;
    color: var(--ui-highlight-color);

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.table-of-contents {
  background: darken($ui-base-color, 4%);
  min-height: 100%;
  font-size: 14px;
  border-radius: 4px;

  li a {
    display: block;
    font-weight: 500;
    padding: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: $primary-text-color;
    border-bottom: 1px solid lighten($ui-base-color, 4%);

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  li:last-child a {
    border-bottom: 0;
  }

  li ul {
    padding-left: 20px;
    border-bottom: 1px solid lighten($ui-base-color, 4%);
  }
}
